<template>
  <div>
    <div class="px-4">
      <p>
        Locating and itemising the assets in your estate can be difficult and time consuming for your Executor. You can make it easier for them by keeping an up to date list of your assets in your Willed Digital Vault.
      </p>
      <div class="d-flex align-center py-2">
        <h3>Your Assets</h3>
        <v-spacer />
        <v-btn text class="bg-grey-20 radius-4" @click="openEstateForm()"
          ><v-icon color="primary" left>mdi-plus-thick</v-icon>Add</v-btn
        >
      </div>
    </div>
    <div class="divider-light" />
    <div v-if="vault_assets.length > 0">
      <transition-group name="component-fade" mode="out-in">
        <v-list-item
          :key="asset.id"
          v-for="(asset) in vault_assets"
          class="border-divider-light"
          tile
        >
          <v-list-item-avatar class="people-avatar" size="36">
            <v-icon size="22" color="black">mdi-chart-line</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <div class="font-weight-bold mb-1">{{ asset.name }}</div>
            <div
              v-if="asset.institution && asset.institution.length > 0"
            >
              {{ asset.institution }}
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              text
              small
              class="bg-grey-30 py-4 radius-4"
              @click="openEstateForm(asset.id)"
              ><v-icon left color="primary">mdi-pencil-outline</v-icon
              >Edit</v-btn
            >
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              icon
              color="black"
              height="32"
              class="bg-grey-30 radius-4"
              @click="deleteEstateConfirm(asset.name, asset.id)"
              ><v-icon>mdi-trash-can-outline</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>
      </transition-group>
    </div>
    <div v-else class="pa-4 border-divider-light">
      <div class="text-no-result">
        <div>Click the <strong>ADD</strong> button on the right to start listing your assets</div>
      </div>
    </div>

    <dialog-small
      :title="selectedAssetName"
      :open.sync="deleteDialog"
      @close="
        () => {
          selectedId = null
          selectedAssetName = null
          deleteDialog = false
        }
      "
    >
      <div class="pa-4">
        <div>Do you want to delete the asset?</div>
      </div>
      <template v-slot:buttons>
        <div class="divider" />
        <v-btn text tile x-large block @click="deleteEstate(selectedId)"
          ><v-icon left color="error">mdi-trash-can-outline</v-icon
          >Delete</v-btn
        >
      </template>
    </dialog-small>
    <dialog-full
      :open.sync="dialogOpen"
      :title="`Assets - ${selectedId ? 'Edit' : 'Add'}`"
      path="/assets"
      base-path="/vault"
    >
      <component
        v-if="openForm"
        :is="`vault-forms-${openForm}`"
        category="assets"
        :id="selectedId"
        @close="close"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Faqs from '../../../components/ui/Faqs'
import DialogFull from '../../../components/wrapper/DialogFull'
import DialogSmall from '../../../components/wrapper/DialogSmall'
import VaultFormsEstate from '../forms/Estate'
import mixins from './mixins'
export default {
  name: 'VaultAssets',
  mixins: [mixins],
  components: {
    VaultFormsEstate,
    DialogFull,
    DialogSmall,
    Faqs,
  },
  data: () => ({
    menu: false,
    openForm: null,
    selectedId: null,
    selectedAssetName: null,
    deleteDialog: false
  }),
  methods: {
    close() {
      this.openForm = null
    },
    deleteEstateConfirm(name, id) {
      if (this.accessStatus !== 'active') {
        this.$emit('accessDenied')
        return
      }

      this.deleteDialog = true
      this.selectedId = id
      this.selectedAssetName = name
    },
    async deleteEstate(id) {

      await this.$store.dispatch(`account/save_vault_assets`, {
        id,
        estate: undefined
      })
      this.deleteDialog = false
    },
    openEstateForm(id = null) {
      if (this.accessStatus !== 'active') {
        this.$emit('accessDenied')
        return
      }
      this.openForm = 'estate'
      this.selectedId = id
    }
  },
  computed: {
    ...mapGetters('account', ['vault_assets']),
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.will-step-icon {
  background-color: var(--primary-10);
}
</style>
